<template>
    <div class="contractdetail">
        <div class="banner-border" v-if="!isDetail">
            <div class="top-banner">
                <div class="background"
                    :style="{ 'background': 'url(' + image + ') no-repeat', 'backgroundSize': '1012px 100%', 'backgroundPosition': 'right', 'opacity': '0.9' }">
                    <div class="banner-left">
                        <div style="display: flex;align-items: center;">
                            <div class="title">房屋回款单号：{{ info.house_back_num }}
                            </div>
                            <div v-if="info.audit_status == '16'" class="shenhe status_type">
                                <img src="@/assets/image/lanr.png" alt="" style="margin-right: 1px;">
                                审核中
                            </div>
                            <div v-if="info.audit_status == '15'" class="huizi status_type">
                                <img src="@/assets/image/huir.png" alt="" style="margin-right: 1px;">
                                未提审
                            </div>
                            <div v-if="info.audit_status == '已撤回'" class="huizi status_type">
                                <img src="@/assets/image/huir.png" alt="" style="margin-right: 1px;">
                                {{ info.audit_status }}
                            </div>
                            <div v-if="info.audit_status == '17'" class="lvzi status_type">
                                <img src="@/assets/image/lvr.png" alt="" style="margin-right: 1px;">
                                审核同意
                            </div>
                            <div v-if="info.audit_status == '19'" class="chengzi status_type">
                                <img src="@/assets/image/cr.png" alt="" style="margin-right: 1px;">
                                财务已确认
                            </div>
                            <div v-if="info.audit_status == '退款中'" class="chengzii status_type">
                                <img src="@/assets/image/cr.png" alt="" style="margin-right: 1px;">
                                {{ info.audit_status }}
                            </div>
                            <div v-if="info.audit_status == '18'" class="hongzi status_type">
                                <img src="@/assets/image/hr.png" alt="" style="margin-right: 1px;">
                                审核拒绝
                            </div>
                        </div>
                        <div class="message">
                            <div class="line">客户名称：<span>{{ info.client }}</span></div>
                            <div class="line">负责人：<span>{{ info.create_name }}</span></div>
                        </div>
                    </div>
                    <div class="banner-right">
                        <button class="dybtn hover" @click="print">
                            <local-svg iconClass="print"></local-svg>
                            <span style="margin-left: 8px;">打印</span>
                        </button>
                        <button class="dybtn hover" @click="generateBill" v-if="!info.releate_invoice && info.audit_status_id == 19  || info.audit_status_id == 19 && info.releate_invoice && info.releate_invoice.audit_status == 20">
                            <local-svg iconClass="generate"></local-svg>
                            <span>一键开票</span>
                        </button>
                        <button class="btn hover" @click="goBack">
                            <local-svg iconClass="back"></local-svg>
                            <span>返回上一页</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="examine-banner">
            <div class="examine-header">
                <div class="header-left">
                    <local-svg icon-class="back" class="icon handel hover" @click.native="backList"></local-svg>
                    <div class="title">房屋回款单号：{{ info.house_back_num }}</div>
                </div>
                <div class="header-right btnpart" v-if="isCheck">
                    <el-button class="confirm" plain @click="financeCheck" v-if="info.audit_status_id == 17"  v-permission="'房屋回款-finance'">财务审核</el-button>
                    <el-button class="confirm" plain @click="agreeCheck"  v-if="info.audit_status_id == 16"  v-permission="'房屋回款-agree'">审核同意</el-button>
                    <el-button class="cancel" plain @click="refuseCheck"  v-if="info.audit_status_id == 17"  v-permission="'房屋回款-finance'">审核拒绝</el-button>
                    <el-button class="cancel" plain @click="refuseCheck"  v-if="info.audit_status_id == 16"  v-permission="'房屋回款-agree'">审核拒绝</el-button>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="descript" :class="{'auto-detail-part':!isDetail,'auto-detail-examine-part':isDetail}">
                <div class="header">
                    详细资料
                </div>
                <!-- <div class="table-element-change dialog-table custom-table" >
                    <div class="table-box">
                        <el-table :data="tableData" v-loading="loading" height="100%" style="width: 100%;" ref="attractTable" tooltip-effect="light">
                            <template slot="empty">
                                <el-empty :image-size="250" :image="image1" description='暂无数据'></el-empty>
                            </template>
                            <el-table-column prop="detail" align="left" label="业务明细"> </el-table-column>
                            <el-table-column prop="money" align="left" label="回款金额"> </el-table-column>
                            <el-table-column prop="big_money" align="left" label="大写金额"> </el-table-column>
                            <el-table-column prop="begin_date" align="left" label="开始时间"> </el-table-column>
                            <el-table-column prop="end_date" align="left" label="结束时间"> </el-table-column>
                        </el-table>
                    </div>
                </div> -->
                <div class="descript-main">
                    <el-descriptions :column="2" border :labelStyle="LS" :contentStyle="CS">
                        <el-descriptions-item v-if="isDetail" label="客户名称">{{ info.client ? info.client : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item v-if="isDetail" label="负责人">{{ info.create_name ? info.create_name : '/'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="回款金额(元)">{{ info.pay_amount ? Number(info.pay_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '/'
                        }}</el-descriptions-item> -->
                        <template v-for="(item,index) in info.business_arr" >
                            <el-descriptions-item :label="'业务明细'+(tableData.length  > 1 ? index+1 : '')" :key="index">{{ item.business_detail.name }}</el-descriptions-item>
                            <el-descriptions-item label="回款金额" :key="index">{{ item.pay_amount }} / {{ bigMoney(item.pay_amount) }}</el-descriptions-item>
                            <el-descriptions-item label="开始时间" :key="index">{{ item.start_time }}</el-descriptions-item>
                            <el-descriptions-item label="结束时间" :key="index">{{ item.end_time }}</el-descriptions-item>
                        </template>
                        <el-descriptions-item label="开票单号">{{ info.releate_invoice && info.releate_invoice.number? info.releate_invoice.number : '/' }}</el-descriptions-item>
                        <el-descriptions-item label="回款方式">{{ info.pay_type ? info.pay_type : '/'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="开始日期">{{ info.start_time ? info.start_time : '/'
                        }}</el-descriptions-item> -->
                        <el-descriptions-item label="房屋回款审核状态">{{ this.info.audit_status == 15 ?'未提审':this.info.audit_status == 16 ?'审核中':this.info.audit_status == 17 ?'审核通过':this.info.audit_status == 18 ?'审核拒绝':'财务已确认'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="结束日期">{{ info.end_time ? info.end_time : '/'
                        }}</el-descriptions-item> -->
                        <el-descriptions-item label="实际到账日期">{{ info.real_time ? info.real_time :
                            '/' }}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{ info.create_time ? info.create_time : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="到账账户">{{ info.pay_account ? info.pay_account : '/'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="业务明细">{{ info.business_detail ? info.business_detail :
                            '/' }}</el-descriptions-item> -->
                        <el-descriptions-item label="所属部门">{{ info.branch_name ? info.branch_name : '/'
                        }}</el-descriptions-item>
                        <!-- <el-descriptions-item label="累计电费回款(元)"></el-descriptions-item>
                        <el-descriptions-item label="累计物业回款(元)"></el-descriptions-item>
                        <el-descriptions-item label="累计租金回款(元)"></el-descriptions-item> -->
                        <el-descriptions-item label="租赁合同">{{ info.house_num ? info.house_num : '/'
                        }}</el-descriptions-item>
                        <el-descriptions-item label="房屋地址">
                            <div :title="info.location"
                                style="width: 461px;overflow:hidden;text-overflow:ellipsis;height: 19px;white-space: nowrap;">
                                <span>{{
                                    info.location ? info.location :
                                    '/' }}</span>
                            </div>
                        </el-descriptions-item>
                        <el-descriptions-item label="照片" :span="2">
                            <div class="image">
                                <div class="addimg-box">
                                    <div class="showimage" v-if="info.img.length > 0" :style="{'background': 'url(' + info.img[0].url + ') no-repeat','backgroundSize':'100% 100%'}">
                                        <div class="tip hover" @click="seeImage">点击查看大图</div>
                                    </div>
                                    <div class="img-total" v-if="info.img.length > 0">共{{ info.img.length }}张</div>
                                    <span v-else>无</span>
                                </div>
                            </div>
                        </el-descriptions-item>
                        <el-descriptions-item label="拒绝原因" :span="2">{{ info.refuse ? info.refuse :
                            '/' }}</el-descriptions-item>
                        <el-descriptions-item label="备注" :span="2">{{ info.remark ? info.remark : '/'
                        }}</el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="examinebtn">
                    <div class="header-right btnpart" v-if="isCheck&&info.handle&&!isDetail">
                        <el-button class="confirm" plain @click="financeCheck" v-if="info.audit_status_id == 17"  v-permission="'房屋回款-finance'">财务审核</el-button>
                        <el-button class="confirm" plain @click="agreeCheck"  v-if="info.audit_status_id == 16"  v-permission="'房屋回款-agree'">审核同意</el-button>
                        <el-button class="cancel" plain @click="refuseCheck"  v-if="info.audit_status_id == 17"  v-permission="'房屋回款-finance'">审核拒绝</el-button>
                        <el-button class="cancel" plain @click="refuseCheck"  v-if="info.audit_status_id == 16"  v-permission="'房屋回款-agree'">审核拒绝</el-button>
                    </div>
                </div>
            </div>
            <div class="detail-right">
                <div class="timetree">
                    <div class="timetitle">
                        <local-svg iconClass="cortanctdetail" class="icon"></local-svg>房屋回款审批进度
                    </div>
                    <div class="tree">
                        <el-timeline>
                            <el-timeline-item v-for="(item, index) in info.audit_process" :key="index">
                                <div class="tree-info" :class="{
                                        'tree5': item.audit_status === 16&&item.create_time, 'tree1': item.audit_status == 17,
                                        'tree2': item.audit_status === 19, 'tree3': item.audit_status === 18,
                                        'tree4': item.audit_status === 16&&item.audit=='审核中'
                                    }">
                                    <div class="timeline">
                                        <div class="date">{{ item.create_time?item.create_time:'-' }}</div>
                                        <div class="status">{{ item.audit }}</div>
                                    </div>
                                    <div class="checkuser">{{ item.name }}</div>
                                    <div class="reason" v-if="item.audit_reject">
                                        <span class="reason-title">拒绝原因：</span>
                                        {{ item.audit_reject }}
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
                <div style="margin-top: 0;" class="user">
                    <div class="table-title">
                        <span class="title">
                            <local-svg iconClass="detailuser" class="icon"></local-svg>跟进人</span>
                        <div class="addpart handel hover" @click="addfl">
                            <local-svg iconClass="add" class="icon"></local-svg>
                            添加
                        </div>
                    </div>
                    <div class="userlist" style="width: 250px;">
                        <div v-if="fArr.length > 0">

                            <div style="width: 240px;" class="fileline hover" v-for="(item, index) in fArr" :key="index">
                                <div>
                                    <span class="name">{{ item.name }}</span>
                                </div>
                                <div class="closepart">
                                    <!-- <span>{{ item.type }}</span> -->
                                    <div class="close-box" @click="delflo(item.id)">
                                        <div class="close">
                                            <local-svg iconClass="close" class="closeicon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <el-empty :image-size="100" :image="image2" description="暂无跟进人，点击添加按钮操作"></el-empty>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="添加跟进人" :visible.sync="showDialog" width="400px" class="addform" @close="cancel" top="15%">
            <div>
                <el-form :model="addform" :rules="rules" ref="addform" :validate-on-rule-change="false">
                    <el-form-item label="选择成员" class="left">
                        <span slot="label"><span class="check-form-notice">*</span>选择成员</span>
                        <!-- <el-input v-model="addform.name" placeholder="请输入用户名"></el-input> -->
                        <el-select v-model="addform.foloow" placeholder="请选择" filterable>
                            <el-option v-for="item in flowList" :label="item.name" :value="item.id"
                                :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer btnpart">
                <!-- <el-button v-if="type == 1" class="confirm" type="primary" @click="addOk('addform')">提 交</el-button> -->
                <el-button class="confirm" type="primary" @click="addhouse('addform')">确定</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
        </el-dialog>
        <refuseExamine v-if="refuseDialog" :show="refuseDialog" @close="cancelRefuse" @examine="confirmRefuse"></refuseExamine>
        <houseAgree v-if="financeDialog" :show="financeDialog" @close="financeCancel" @examine="financeConfirm"></houseAgree>
        <showImage :previewImg="imageData"></showImage>
    </div>
</template>
<script>
import { addFollower, allfollow, delfollower, backde ,financeback,examineback} from '@/views/houseCollection/api'
import houseAgree from '@/components/examine/houseagree'
import refuseExamine from '@/components/examine/refuse'
import showImage from '@/components/showImage.vue'
import { hosuedetail } from '@/api/house';
import { dandetails } from '@/views/houseContract/api';
export default {
    name: 'backdetail',
    components: {
        refuseExamine,
        houseAgree,
        showImage
    },
    props: {
        danList: {
            type: Object
        },
        isDetail:{
            type: Boolean,
            default: () => {
                return false
            }
        },
        house_num:{
            type: String,
            default: () => {
                return ''
            }
        }
    },
    data() {
        return {
            isCheck :true,
            refuseDialog:false,
            financeDialog:false,
            showDialog: false,
            loading:false,
            // image: require('../../assets/image/detail.png'),
            image: require('@/assets/image/detail.png'),
            image1: require('@/assets/image/none.png'),
            image2: require('@/assets/image/noneuser.png'),
            LS: {
                "word-break": "keep-all"
            },
            CS: {
                "max-width": "100px",
                "word-break": "break-all"
            },
            addform: {
                foloow: ''
            },
            rules: {
                foloow: [
                    { required: true, message: '成员不能为空', trigger: 'blur' },
                ]
            },
            flowList: [],
            tableData:[{
                detail:'业务明细',
                money:'200.00',
                big_money:'二百圆',
                begin_date:'2024-05-09',
                end_date:'2024-05-10',
            },{detail:'业务明细',
                money:'200.00',
                big_money:'二百圆',
                begin_date:'2024-05-09',
                end_date:'2024-05-10',},{

                }],
            fArr: this.danList.follower,
            info:{
                img:[]
            },
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
        }
    },
    mounted(){
        this.info = this.danList  
    },
    methods: {
        seeImage(){
            this.imageData.popup = true
            this.imageData.imgList = this.info.img
        },
        backList(){
            this.$router.push({path:'/todolist'})
        },
        financeCheck(){
            this.financeDialog = true
        },
        financeConfirm(msg){
            let data = {
                id:this.info.id,
                pay_account:msg.get_account,
                real_time:msg.real_time,
                finance_opinion:msg.finance_opinion
            }
            this.$loading({ text: '加载中...' });
            financeback(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.financeDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        financeCancel(){
            this.financeDialog = false
        },
        cancelRefuse(){
            this.refuseDialog = false
        },
        generateBill(){
            if(this.info.releate_invoice && this.info.releate_invoice.audit_status != 20){
                this.$message.error('此回款已开票，请问重复操作！')
                return
            }
            dandetails(this.house_num).then(res=>{
                if(res.code == 200){
                    let total_amount = 0
                    this.info.business_arr.forEach(item =>{
                        total_amount = Number(item.pay_amount) + total_amount
                    })
                    this.$router.push({
                        name:'openbill',
                        params:{
                            type:'generate',
                            way:2,
                            client:this.info.client_id,
                            client_name:this.info.client,
                            id:this.info.id,
                            house_id:this.info.h_c_id,
                            amount:total_amount.toFixed(2),
                            channel:this.info.channel? this.info.channel : '暂无',
                            source:this.info.source ? this.info.source : '暂无',
                            address:this.info.location,
                            date:res.data.start_time + ',' + res.data.end_time
                        }
                    })
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        confirmRefuse(msg){
            let data = {
                id:this.info.id,
                audit_status:18,
                refuse:msg.reason
            }
            this.$loading({ text: '加载中...' });
            examineback(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.refuseDialog = false
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        seeDetail(msg) {
            backde(msg).then(res => {
                this.info = res.data

            })
        },
        agreeCheck(){
            let data = {
                id:this.info.id,
                audit_status:17,
                refuse:''
            }
            this.$loading({ text: '加载中...' });
            examineback(data).then(res=>{
                if(res.code == 200){
                    this.seeDetail(this.info.id)
                    this.isCheck = false
                    this.$message({
                        message:res.msg,
                        type:'success'
                    })
                }else{
                    this.$message({
                        message:res.msg,
                        type:'error'
                    })
                }
                this.$loading().close()
            }).catch(err => {
                this.$message.error("请求超时")
                this.$loading().close()
            })
        },
        refuseCheck(){
            this.refuseDialog = true
        },
        goBack() { this.$emit('back', false) },
        async addfl() {
            this.showDialog = true
            let res = await allfollow()
            // let res = await allfollow(this.danList.branch_id)
            this.flowList = res.data
            // console.log(res);
            // console.log(this.danList.branch_id);
        },
        cancel() {
            // this.$emit('cancel', false)
            this.$refs.addform.resetFields();
            this.addform = {
                foloow: ''
            }
            this.showDialog = false


        },
        addhouse(addform) {
            this.$refs[addform].validate(async (valid) => {
                if (valid) {
                    let data = {
                        id: this.info.id,
                        follower: this.addform.foloow
                    }
                    let res = await addFollower(data)
                    if (res.code === 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.showDialog = false
                        let ress = await backde(this.info.id)
                        // console.log(ress.data.follower);
                        if (this.fArr !== ress.data.follower) {
                            this.fArr = ress.data.follower
                        }

                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                        this.showDialog = false
                    }
                    // console.log(res);
                    // console.log(this.info.id);
                    // console.log(this.addform.foloow);
                }
            })
        },
        delflo(id) {
            this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await delfollower(id)
                if (res.code === 200) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.showDialog = false
                    let ress = await backde(this.info.id)
                    // console.log(ress.data.follower);
                    if (this.fArr !== ress.data.follower) {
                        this.fArr = ress.data.follower
                    }

                }
                // this.$emit('deluser', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
            // console.log(1);
            // console.log(id);

            // console.log(res);
        },
        print() {
            this.$emit('print',this.info)
            // window.print()
            // let oldStr = window.document.body.innerHTML;
            // let newStr = document.getElementById('www').innerHTML;
            // window.document.body.innerHTML = newStr;
            // window.print();
            // window.document.body.innerHTML = oldStr;


        },
        numToCapital(money) {
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        bigMoney(num) {
            let number = Number(num)
            if(number && number>=0){
                return this.numToCapital(number)
            }else if(number && number<0){
                return '负' + this.numToCapital(Number(number)*-1)
            }
        },
    },
    // watch: {
    //     info: {
    //         handler(newV, oldV) {
    //             this.flowList = newV.follower
    //         },
    //         deep: true
    //     }
    // }
}
</script>
<style lang="scss" scoped>
.contractdetail {
    .filedialog {
        width: 800px !important;
        height: 500px !important;
    }
    
    .top-banner {
        width: 100%;
        height: 112px;
        border-radius: 4px;
        background: linear-gradient(90deg, #FFF 0%, #F0F4FE 50%, #F0F4FE 100%);
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        .background {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 26px 30px;
            display: flex;
            justify-content: space-between;

            .banner-left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    font-size: 22px;
                    font-weight: bold;
                    color: #474952;
                    // max-width: 570px;
                    width: auto;
                    display: flex;
                    align-items: center;

                    .tip {
                        width: auto;
                        height: 24px;
                        line-height: 24px;
                        padding: 0 10px;
                        border-radius: 12px 2px 12px 2px;
                        font-size: 14px;
                    }

                    .status1 {
                        color: #8C8EA6;
                        background: #DADEEC;
                    }

                    .status2 {
                        color: #2C57FF;
                        background: #D4DEFF;
                    }

                    .status3 {
                        color: #08B54D;
                        background: #C9EFDD;
                    }

                    .status4 {
                        color: #F74B4B;
                        background: #FFE3E3;
                    }

                    .status5 {
                        color: #DE8500;
                        background: #FFEECD;
                    }
                }

                .message {
                    display: flex;

                    .line {
                        font-size: 14px;
                        color: #C4C5CB;
                        margin-right: 20px;

                        span {
                            color: #7D7F88;
                        }
                    }

                    .active {
                        color: #8999B1;
                    }
                }
                .status_type{
                    font-size: 14px
                }
                .shenhe {
                    width: 80px;
                    height: 24px;
                    background: #D4DEFF;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #2C57FF;
                }


                .huizi {
                    width: 80px;
                    height: 24px;
                    background: #DADEEC;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #8C8EA6;
                }

                .lvzi {
                    width: 96px;
                    height: 24px;
                    background: #C9EFDD;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #08B54D;
                }

                .chengzi {
                    width: 108px;
                    height: 24px;
                    background: #FFEECD;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #DE8500;
                }

                .chengzii {
                    width: 80px;
                    height: 24px;
                    background: #FFEECD;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #DE8500;
                }

                .hongzi {
                    width: 96px;
                    height: 24px;
                    background: #FFE3E3;
                    border-radius: 12px 2px 12px 2px;
                    opacity: 1;
                    margin-left: 12px;
                    text-align: center;
                    line-height: 27px;
                    color: #F74B4B;
                }
            }

            .banner-right {
                display: flex;
                align-items: center;

                .btn {
                    width: 128px;
                    height: 32px;
                    box-shadow: 0px 2px 4px 0px rgba(55, 59, 92, 0.1);
                    background: linear-gradient(180deg, #F8F8FB 0%, #ECEFF6 100%);
                    border-radius: 4px 4px 4px 4px;
                    outline: none;
                    border: none;
                    font-size: 14px;
                    color: #6B6D77;

                    span {
                        margin-left: 8px;
                    }
                }

                .btn:hover {
                    background: linear-gradient(180deg, #ECEFF6 0%, #F8F8FB 100%);
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 700px;
        
        // background: white;
        margin-top: 10px;
        display: flex;

        .title {
            font-weight: bold;
            font-size: 14px;
        }
        .table-box{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            padding: 0 30px;
            margin-bottom: 12px;
        }
        .descript {
            width: calc(100% - 292px);
            height: 700px;
            background-color: white;
            border-radius: 4px;
            max-height: calc(100vh - 230px) !important;
            overflow-y: auto;

            .header {
                color: #474952;
                font-weight: 700;
                height: 55px;
                border-bottom: none !important;
                display: flex;
                padding: 24px 0 0 30px;
                box-sizing: border-box;
                font-size: 14px;

                .tab {
                    width: auto;
                    line-height: 16px;
                    margin-right: 40px;
                    color: #B0B1B9;
                }

                .tab:hover {
                    color: #2C57FF;
                }

                .acttab {
                    border-bottom: 2px solid #2C57FF;
                    font-weight: bold;
                    color: #474952 !important;
                }
            }

            .descript-main {
                padding: 28px 30px;
                box-sizing: border-box;
                margin-top: 5px;
                .addimg-box{
                    display: flex;
                    align-items: flex-end;
                    .img-total{
                        margin-left: 10px;
                        font-size: 12px;
                        color: rgb(211, 211, 211);
                    }
                }
                .showimage{
                    width: 120px;
                    height: 80px;
                    .tip{
                        background: rgba(51,54,62,0.5);
                        width: 100%; 
                        height: 100%;
                        display: none;
                        justify-content: center;
                        align-items: center;
                        color: rgb(211, 211, 211);
                    } 
                    img{
                        width: 120px;
                        height: 80px;
                    }
                }
                .showimage:hover{
                    .tip{
                        display: flex;
                    }
                }
                .filelist {
                    width: 100%;
                    height: 120px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 243px;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 8px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }
                }

                .table {
                    .table-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 14px;

                        .title {
                            font-weight: bold;
                            color: #6B6D77;
                            margin-bottom: 14px;
                        }

                        .addpart {
                            .icon {
                                fill: #475B93 !important;
                            }
                        }

                        .addpart:hover {
                            color: #2C57FF !important;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }

                        .addpart:active {
                            color: #133CDC !important;

                            .svg-icon {
                                fill: #133CDC !important;
                            }
                        }
                    }
                }
            }
        }

        .detail-right {
            margin-left: 10px;
            width: 282px;

            .timetree {
                // width: 100%;
                // height: calc(100% - 256px);

                // border-radius: 4px;
                // background: white;
                // padding: 24px 20px;
                // box-sizing: border-box;
                margin-bottom: 10px;

                width: 100%;
                max-height: calc(100% - 256px);
                height: auto;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .timetitle {
                    padding: 0 7px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;

                    .icon {
                        margin-right: 5px;
                    }
                }

                .tree {
                    height: calc(100% - 38px);
                    margin-top: 20px;
                    width: 100%;
                    overflow-y: auto;
                    padding: 0 4px;
                    box-sizing: border-box;

                    .el-timeline-item__node--normal {
                        width: 14px !important;
                        height: 14px !important;
                        left: -3px;
                    }

                    .el-timeline-item__node {
                        background: #FFF !important;
                        border: 1px solid #E2E5EA !important;
                    }

                    .el-timeline-item__wrapper {
                        top: 0 !important;
                        padding-left: 21px !important;
                    }

                    .el-timeline-item {
                        position: relative;
                        padding-bottom: 1px;
                    }

                    .el-timeline-item__tail {
                        border-left: 1px solid #E4E7ED !important;
                    }

                    .tree-info {
                        min-height: 64px;
                        width: 100%;
                        border-radius: 4px;
                        // background: red;
                        background: #E9EAEE;
                        padding: 12px 14px 14px;
                        box-sizing: border-box;

                        .status {
                            color: #8C8EA6 !important;
                        }
                    

                        .timeline {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 12px;
                            line-height: 14px;

                            .date {
                                color: #B0B1B9;
                            }

                            .status {
                                color: #B0B1B9;
                            }
                        }

                        .checkuser {
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: bold;
                            color: #6B6D77;
                            margin-top: 6px;
                        }

                        .reason {
                            color: #6B6D77;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 6px;

                            .reason-title {
                                color: #B0B1B9;
                            }
                        }
                    }

                    .tree1 {
                        background: #F0FAF1 !important;

                        .status {
                            color: #10C257 !important;
                        }
                    }

                    .tree2 {
                        background: #FAF5E6 !important;

                        .status {
                            color: #DE8500 !important;
                        }
                    }

                    .tree3{
                        background: #FFF4F4 !important;

                        .status {
                            color: #F74B4B !important;
                        }
                    }
                    .tree4{
                        background: #EAF1FB !important;
                        .status{
                            color: #2C57FF !important;
                        } 
                    }
                    .tree5{
                        background: #EAF7FF !important;
                        .status{
                            color: #0C80EB !important;
                        } 
                    }

                }
            }

            .user {
                // margin-top: 10px;
                width: 100%;
                height: 246px;
                border-radius: 4px;
                background: white;
                padding: 24px 20px;
                box-sizing: border-box;

                .table-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 14px;

                    .title {
                        font-weight: bold;
                        color: #474952;

                        .icon {
                            width: 14px;
                            height: 14px;
                            margin-right: 10px;

                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .addpart {
                        .icon {
                            fill: #475B93 !important;
                        }
                    }

                    .addpart:hover {
                        color: #2C57FF !important;

                        .svg-icon {
                            fill: #2C57FF !important;
                        }
                    }

                    .addpart:active {
                        color: #133CDC !important;

                        .svg-icon {
                            fill: #133CDC !important;
                        }
                    }
                }

                .userlist {
                    margin-top: 16px;
                    height: 172px;
                    overflow-y: auto;
                    padding-right: 8px;
                    box-sizing: border-box;

                    .el-empty {
                        padding: 10px 0 !important;
                    }

                    .fileline {
                        display: flex;
                        height: 28px;
                        border-radius: 4px;
                        background: #F4F7FC;
                        width: 100%;
                        align-items: center;
                        margin-bottom: 6px;
                        padding: 0 12px 0 14px;
                        justify-content: space-between;
                        box-sizing: border-box;

                        .icon {
                            margin-right: 7px;
                            width: 12px;
                            height: 14px;
                        }

                        .name {
                            font-size: 12px;
                            color: #474952;
                        }

                        .close {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: rgba(63, 69, 124, 0.1);
                            display: none;
                            align-items: center;
                            justify-content: center;

                            .closeicon {
                                width: 8px;
                                height: 8px;
                                fill: #B0B1B9;
                            }
                        }

                        .close:hover {
                            background: rgba(44, 87, 255, 0.2) !important;

                            .closeicon {
                                fill: #2C57FF !important;
                            }
                        }
                    }

                    .fileline:hover {
                        background: #EDF3FE !important;

                        .close {
                            display: flex;
                        }
                    }

                    .closepart {
                        display: flex;
                        align-items: center;

                        .close-box {
                            width: 16px;
                        }

                        span {
                            font-size: 14px;
                            line-height: 16px;
                            color: #B0B1B9;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}

:deep(.descript-main) {
    padding-top: 0 !important;
}

.dybtn {
    width: auto;
    padding: 0 16px;
    height: 32px;
    background: linear-gradient(180deg, #355EFF 0%, #1744F2 100%);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    color: #FFF;
    text-align: center;
    line-height: 32px;
    border: none;
    margin-right: 10px;
}

.dybtn:hover {
    // background: linear-gradient(180deg, #3f63f1 0%, #3f63f1 100%);

}
</style>
