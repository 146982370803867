<template>
    <div class="adduser">
        <el-dialog :title="title" :visible.sync="showDialog" width="1000px" class="addform" @close="cancel" :close-on-click-modal="false">
            <div class="headtab" v-if="addform.have==1||addform.have==''">
                <div class="tab-box hover">
                    <div class="tab" v-for="(item,index) in tabList" :key="index" :class="{'acttab':actTabIndex==index}" @click="changTab(index)">{{ item.name }}</div>
                </div>
            </div>
            <div class="addform applyform">
                <div class="formbox" v-show="actTabIndex==0">
                    <el-form :model="addform" :rules="rules" ref="addform" >
                        <el-row>
                            <el-col :span="11">
                                <el-form-item prop="amount" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>支出金额</span>
                                    <el-input v-model="addform.amount" placeholder="请输入支出金额" @input="changeChinese" @blur="changThousand(1)"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="支出类型" prop="business_type">
                                    <span slot="label"><span class="check-form-notice">*</span>支出类型</span>
                                    <el-select v-model="addform.business_type" placeholder="请选择" @change="getBusDetail" filterable>
                                        <el-option v-for="item in busList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="大写金额" class="left" prop="amount_yuan">
                                    <el-input v-model="addform.amount_yuan"  disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" >
                                <el-form-item label="支出明细" prop="charge_detail">
                                    <span slot="label"><span class="check-form-notice">*</span>支出明细</span>
                                    <el-select v-model="addform.charge_detail" filterable :placeholder="addform.business_type==''?'请先选择支出类型':'请选择'" :disabled="addform.business_type==''?true:false">
                                        <el-option v-for="item in busdetailList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="审核状态" class="left" v-if="type==2">
                                    <el-select v-model="addform.audit_status" placeholder="请选择" disabled>
                                        <el-option v-for="item in statusList" :label="item.value" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="type==2">
                                <el-form-item label="拒绝原因" prop="pay_type">
                                    <el-input v-model="addform.refuse" placeholder="请输入" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="实际支出时间" class="left" v-if="addform.audit_status==66">
                                    <span slot="label"><span class="check-form-notice">*</span>实际支出时间</span>
                                    <el-date-picker v-model="addform.pay_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" :picker-options="pickerOptions"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right" v-if="addform.audit_status==66">
                                <el-form-item label="支出账户" prop="pay_type">
                                    <span slot="label"><span class="check-form-notice">*</span>支出账户</span>
                                    <el-select v-model="addform.pay_account_id" placeholder="请选择">
                                        <el-option v-for="item in payAccountList" :label="item.value" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="所属部门" class="left" prop="branch_id">
                                    <span slot="label"><span class="check-form-notice">*</span>所属部门</span>
                                    <el-input v-model="form.branch_name" disabled v-if="type==2&&form.audit_status!=62"></el-input>
                                    <el-select v-else v-model="addform.branch_id" placeholder="请选择" >
                                        <el-option v-for="item in branchList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="支出方式" prop="pay_type">
                                    <span slot="label"><span class="check-form-notice">*</span>支出方式</span>
                                    <el-select v-model="addform.pay_type" placeholder="请选择" >
                                        <el-option v-for="item in paytypeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="收款方" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>收款方</span>
                                    <!-- <el-select v-model="addform.b_a_id" placeholder="请选择">
                                        <el-option v-for="item in team" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select> -->
                                    <div class="handel hover" @click="checkReceive">{{receivename}}</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="支出公司" class="left" prop="pay_company">
                                    <span slot="label"><span class="check-form-notice">*</span>支出公司</span>
                                    <el-select v-model="addform.pay_company" placeholder="请选择" filterable>
                                        <el-option v-for="item in companyList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="凭证" class="left" prop="proof">
                                    <span slot="label"><span class="check-form-notice">*</span>凭证</span>
                                    <el-select v-model="addform.proof" placeholder="请选择" @change="checkProof">
                                        <el-option v-for="item in proofList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                
                            </el-col>
                            <el-col :span="11" class="right">
                                <el-form-item label="支出事由" class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>支出事由</span>
                                    <el-input v-model="addform.cause" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="11">
                                
                            </el-col> -->
                            <!-- <el-col :span="11" class="right">
                                <el-form-item label="有无回款明细" prop="have">
                                    <span slot="label"><span class="check-form-notice">*</span>有无回款明细</span>
                                    <el-select v-model="addform.have" placeholder="请选择">
                                        <el-option v-for="item in havenoList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                
                                <el-form-item class="left">
                                    <span slot="label"><span class="check-form-notice">*</span>有无票据</span>
                                    <el-select v-model="addform.bill_status" placeholder="请选择" :disabled="addform.proof == '33'">
                                        <el-option v-for="item in haveBillList" :label="item.name" :value="item.id" :key="item.id" :disabled="item.disabled"></el-option>
                                    </el-select>
                                </el-form-item>
                                <!-- <el-form-item class="left" prop="pay_back_num">
                                    <span slot="label">
                                        <span slot="label"><span class="check-form-notice">*</span>对应回款单号</span>
                                        <el-tooltip content="行政类填无" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-input v-model="addform.pay_back_num" placeholder="请输入"></el-input>
                                </el-form-item> -->
                                <!-- <el-form-item class="left" prop="pay_back_num">
                                    <span slot="label">
                                        <span slot="label"><span class="check-form-notice">*</span>对应回款单号</span>
                                        <el-tooltip content="行政类填无" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-input v-model="addform.pay_back_num" placeholder="请输入"></el-input>
                                </el-form-item> -->
                                <el-form-item class="left" label="">
                                        <span slot="label"><span class="check-form-notice">*</span>是否为渠道返点</span>
                                    <el-select v-model="addform.is_channel" placeholder="请选择">
                                        <el-option v-for="item in channelList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="有无回款明细" prop="have" class="left">
                                    <span slot="label">
                                        <span slot="label"><span class="check-form-notice">*</span>有无回款明细</span>
                                        <el-tooltip content="行政类填无" placement="bottom" effect="light"  popper-class="handlerTab_tooltip">
                                            <local-svg iconClass="tips" class="handelicon hover" style="margin-left: 4px;"></local-svg>
                                        </el-tooltip>
                                    </span>
                                    <el-select v-model="addform.have" placeholder="请选择" @change="changeHave">
                                        <el-option v-for="item in havenoList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item class="left" label="备注">
                                    <!-- <el-input v-model="addform.remark" autocomplete="off" type="textarea" rows="5" class="describe remark" placeholder="请输入备注"></el-input> -->
                                    <el-input v-model="addform.remark" placeholder="请输入备注" ></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- <el-col :span="11" class="right">
                                <el-form-item label="" class="long-input btnpart uploadfile"  prop="file">
                                    <span slot="label">图片</span>
                                    <div class="file">   
                                        <div class="filepart">
                                            <el-upload
                                                class="upload-demo"
                                                :http-request="uploadFile"
                                                action=""
                                                >
                                                <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon" plain></local-svg>上传图片</el-button>
                                            </el-upload>
                                            <div class="filetip"><span style="color: #F74B4B;">最多上传9张</span></div>
                                            <div class="filesize">支持扩展名：.jpg .jpeg .png，大小不超过9M</div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>  
                            <el-col :span="11" class="right">
                                <div class="filelist1">
                                    <div class="leftpart">
                                        <div class="left-btn hover change" @click="lastFile" v-show="actIndex!=0">
                                            <local-svg iconClass="swipericon" class="lefticon"></local-svg>
                                        </div>
                                    </div>
                                    <div class="fileline" v-for="(item,index) in showFile" :key="index">
                                        <div class="file-box">
                                            <img :src="item.img_url" alt="" class="image hover" @click="seeImageList(index)">
                                            <div class="close hover" @click.stop="delFile(index)">
                                                <local-svg iconClass="close" class="closeicon"></local-svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rightpart">
                                        <div class="right-btn hover change" @click="nextFile" v-show="fileList.length>4&&actIndex+4!=fileList.length">
                                            <local-svg iconClass="swipericon" class="righticon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                            </el-col> -->
                            <el-col :span="11" class="right file-right">
                                <el-form-item :label="addform.paper!=2?'文件资料':''" class="long-input btnpart"  >
                                    <span slot="label"><span class="check-form-notice">{{addform.bill_status == 60 ? '*' :''}}</span>文件资料</span>
                                    <div class="file" v-if="addform.paper!=2">   
                                        <div class="filepart">
                                            <el-upload
                                                class="upload-demo"
                                                :http-request="uploadFile"
                                                action=""
                                                >
                                                <el-button class="uploadbtn"><local-svg iconClass="upload" class="icon" plain></local-svg>上传附件</el-button>
                                            </el-upload>
                                            <div class="filetip"><span>文件大小不超过50MB</span></div>
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-col>  
                            <el-col :span="11" class="right file-right" >
                                <div class="filelist2" v-if="addform.paper!=2" :class="{'nopaper1':type==2}" >
                                    <div class="fileline hover" v-for="(item,index) in fileList" :key="index" @click="seeFile(item)">
                                        <div>
                                            <local-svg iconClass="file" class="icon"></local-svg>
                                            <span class="name">{{ item.name }}</span>
                                        </div>
                                        <div class="close" @click.stop="fileList.splice(index,1)">
                                            <local-svg iconClass="close" class="closeicon"></local-svg>
                                        </div>
                                    </div>
                                </div>
                                <div  class="filelist" v-else :class="{'nopaper':type==2}"></div>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
                <div class="formbox" v-show="actTabIndex==1">
                    <div class="table-main">
                        <div class="table-title">
                            <span class="title">回款明细列表</span>
                            <div class="addpart handel hover" @click="addDetail">
                                <local-svg iconClass="add" class="icon"></local-svg>
                                新建明细
                            </div>
                        </div>
                        <div class="table">
                            <el-table
                                :data="addform.fee_apply_details"
                                height="360px"
                                style="width: 100%"
                                :header-cell-style="{'background':'#F4F7FC','color':'#B0B1B9','fontWeight':'bold'}"
                                show-overflow-tooltip>
                                <template slot="empty">
                                    <el-empty :image-size="160" :image="image" description='请点击新建按钮，新建明细'></el-empty>
                                </template>
                                <!-- <el-table-column type="index" label="序号"  align="center"></el-table-column> -->
                                <el-table-column label="回款类型" width="120" fixed="left">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.type" placeholder="请选择">
                                            <el-option v-for="item in contracttyprList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="客户名称" width="250" >
                                    <template slot-scope="scope">
                                        <el-select class="hideinput" v-model="scope.row.client_id" placeholder="请选择" @change="getBackList(scope.row,scope.$index)" filterable clearable>
                                            <el-option v-for="item in customList" :label="item.name" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="回款单号"  width="180">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.back_id" :placeholder="scope.row.client_id?'请选择':'请先选择客户名称'"  v-if="scope.row.type==2"
                                        :disabled="scope.row.client_id?false:true" @change="getDetailList(scope.row,scope.$index)" filterable>
                                            <el-option v-for="item in scope.row.backlist" :label="item.number" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                        <el-select v-model="scope.row.back_id" :placeholder="scope.row.client_id?'请选择':'请先选择客户名称'"  v-else
                                        :disabled="scope.row.client_id?false:true" @change="getDetailList(scope.row,scope.$index)" filterable>
                                            <el-option v-for="item in scope.row.backlist" :label="item.num" :value="item.id" :key="item.id"></el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="回款支出金额" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.disburse_amount" :placeholder="scope.row.back_id==''?'请先选择回款单号':'请输入'" :disabled="!scope.row.back_id" @blur="changThousand(2,scope.$index)"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="业务类型"  width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.business_type" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="money" label="业务明细" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.business_detail" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="回款金额" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.back_amount" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="money" label="回款摘要" width="200">
                                    <template slot-scope="scope">
                                        <el-tooltip :content="scope.row.back_summary" placement="top" v-if="scope.row.back_summary">
                                            <el-input  v-model="scope.row.back_summary" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                        </el-tooltip>
                                        <el-input  v-model="scope.row.back_summary" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled v-else></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="实际到账时间" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.real_time" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="money" label="到款账户" width="200">
                                    <template slot-scope="scope">
                                        <el-tooltip :content="scope.row.get_account" placement="top" v-if="scope.row.get_account">
                                            <el-input  v-model="scope.row.get_account" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                        </el-tooltip>
                                        <el-input  v-model="scope.row.get_account" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled v-else></el-input>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="detail" label="系统来源" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.source" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="渠道名称" width="120">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.channel" :placeholder="scope.row.back_id==''?'请先选择回款单号':'无'" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="detail" label="返点金额" width="120">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.return_amount" :placeholder="scope.row.back_id==''?'请先选择回款单号':'请输入'" :disabled="!scope.row.channel || scope.row.channel=='无'"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="80" fixed="right" align="center">
                                    <template slot-scope="scope">
                                        <span class="handel hover" @click="delRow(scope.row,scope.$index)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer btnpart" v-if="addform.audit_status == 62 || !addform.audit_status">
                <el-button v-if="type==1" class="confirm" type="primary" @click="addOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==1" class="savebtn" type="primary" @click="addOk('addform','2')">保存</el-button>
                <el-button v-if="type==2" class="confirm" type="primary" @click="editOk('addform','1')">保存并提交</el-button>
                <el-button v-if="type==2" class="savebtn" type="primary" @click="editOk('addform','2')">保存</el-button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
            <div slot="footer" class="dialog-footer btnpart button-part" v-else>
                <button class="again hover" style="margin-right: 10px;" @click="editOk('addform','1')">重新提交</button>
                <el-button class="cancel" @click="cancel">取消</el-button>
            </div>
            
        </el-dialog>
        <receiveCost v-if="receiveDialog" :showdialog="receiveDialog" @add="checkUser" @closereceive="closereceive" :actuser="addform.b_a_id"></receiveCost>
        <showImage :previewImg="imageData"></showImage>
    </div>
</template>

<script>
import {custom} from '@/api/common'
import {addselect,getbusselect,houselist,contractist,contractinfo,backdetail1,channelinfo} from '@/api/const'
import showImage from '@/components/showImage.vue'
import md5 from 'js-md5'
import service from "@/utils/request.js";
import { Loading } from 'element-ui';
import receiveCost from '@/components/constapply/receive.vue'
export default {
    name: "adduser",
    props: {
        show:{
        	type:Boolean,
        	default:()=>{
        		return false
        	}
        },
        form:{
            type:Object,
        	default:()=>{
        		return false
        	}
        },
        type:{
            type:Number,
            default:() =>{
                return 1
            }
        },
    },
    components:{
        showImage,
        receiveCost
    },
    data() {
        return {
            receiveDialog:false,
            uploadUrl:process.env.VUE_APP_BASE_URL+'/common/uploadImg',
            image:require('../../assets/image/none.png'),
            list:[],
            actIndex:0,
            actTabIndex:0,
            tabList:[
                {name:'基本信息'},
                {name:'回款明细'}
            ],
            receivename:'-请选择收款方-',
            fileList:[],
            fileList2:[],
            companyList:[],   //支出公司列表
            paytypeList:[],   //支出方式列表
            proofList:[],   //凭证列表
            belongList:[],   //款项所属公司列表
            feeList:[],   //费用类型数据列表
            billList:[],  //票据列表
            channelList:[{name:'是',id:1},{name:'否',id:2}],
            haveBillList:[
                {name:'已提供票据',id:60},
                {name:'待提供票据',id:61},
                {name:'无票据',id:59,disabled: true},
            ],
            statusList:[],   //审核状态列表
            accountList:[], //支出账户列表
            branchList:[],  //部门列表
            busList:[],   //支出类型列表
            busdetailList:[],   //支出明细列表
            payAccountList:[],
            customList:[],
            showFile:[],
            contracttyprList:[
                {id:2,name:'回款记录'},
                {id:1,name:'房屋回款'},
            ],
            havenoList:[
                {id:1,name:'有'},
                {id:2,name:'无'},
            ],
            contractList:[],
            houseList:[],
            addform:{
                audit_status:62,
                amount:'',
                business_type:'',
                amount_yuan:'',
                charge_detail:'',
                pay_type:'',
                branch_id:'',
                pay_company:'',
                proof:'',
                pay_back_num:'',
                remark:'',
                b_a_id:'',
                fee_apply_details:[],
                have:'',
                is_channel:'',
            },
            sellList:[],
            title:'新建费用申请',
            showDialog:false,
            rules: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            imageData:{
                imgList:[], // 所有图片数组
                index: 0, // 当前点击的图片的索引
                infinite: true,  // 是否可以循环切换
                popup: false // 弹窗的显示隐藏
            },
        };
    },
    mounted(){
        this.showDialog = this.show
        this.getSelectList()
        this.getBusList()
        this.getCustom()
        if(this.type == 2 ){
            this.title = '编辑费用申请'
            this.addform = this.form
            this.addform.fee_apply_details = []
            this.addform.business_type = this.form.business_type_id
            this.addform.pay_type = this.form.pay_type_id
            this.addform.pay_company = this.form.pay_company_id
            this.$set(this.addform,'have',this.form.details.length>0?1:2)
            this.addform.b_a_id = this.form.b_a_id
            this.receivename = this.form.receive_name
            this.addform.proof = this.form.proof_id
            this.addform.charge_detail = this.form.charge_detail_id
            this.$set(this.addform,'bill_status',this.form.bill_status_id)
            this.changThousand(1)
            this.changeChinese()
            this.setTable()
            this.getBusDetail()
            // this.checkProof()
            this.fileList = this.form.image
            this.fileList.forEach(item =>{
                item.img_url = item.image
            })
            this.showFile = this.fileList.slice(0,4)
        }
    },
    methods: {
        changeHave(){
            if(this.addform.have == 2){
                this.addform.fee_apply_details = []
            }
        },
        checkProof(){
            // this.$set(this.addform,'bill_status',this.addform.proof == '33'? 59 :'')
            this.$set(this.addform,'bill_status',this.addform.proof == '33'? 59 :'')

        },
        seeFile(item){
            // let url = process.env.VUE_APP_BASE_URL + '/' +item.file_url
            let url = item.img_url
            window.open(url,'_blank')
        },
        changThousand(type,index){
            if(type == 1){
                this.addform.amount = Number(this.addform.amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
            }else{
                this.$set(this.addform.fee_apply_details[index],'disburse_amount',Number(this.addform.fee_apply_details[index].disburse_amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }))
            }
        },
        getCustom(){
            custom().then(res=>{
                this.customList = res.data
            })
        },
        setTable(){
            this.addform.details.forEach(item =>{
                let data = {
                    back_id:Number(item.back_id),
                    client_id:item.client_id,
                    business_type: item.business_type,
                    business_detail: item.business_detail,
                    type:item.type=='房屋回款'?1:2,
                    back_amount : Number(item.back_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    disburse_amount:Number(item.disburse_amount).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    back_summary : item.back_summary,
                    back_num:item.back_num,
                    id:item.id
                }
                data.channel = data.type==2 && item.channel ? item.channel : '无'
                data.source = data.type==2 && item.source ? item.source : '无'
                data.channel_id = data.type==2 && item.channel_back_amount ? item.channel_back_amount.ciid : ''
                data.return_amount = data.type==2 && item.channel_back_amount ? Number(item.channel_back_amount.amount) : ''
                let msg = {
                    client_id:data.client_id,
                    type:data.type
                }
                contractinfo(msg).then(res=>{
                    data.backlist = res.data.payBack?res.data.payBack:res.data.houseBack
                })
                this.addform.fee_apply_details.push(data)
            })
            this.getTableInfo()
        },
        getTableInfo(){
            this.addform.fee_apply_details.forEach(item =>{
                this.getTime(item)
            })
        },
        getTime(row,index){
            let data = {
                back_id:row.back_id,
                type:row.type
            }
            backdetail1(data).then(res =>{
                row.real_time = row.type==2?res.data.payBack.real_time:res.data.houseBack.real_time
                row.get_account = row.type==2?res.data.payBack.get_account:res.data.houseBack.pay_account
            })
        },
        getBackList(row){
            let data = {
                client_id:row.client_id,
                type:row.type
            }
            contractinfo(data).then(res=>{
                row.backlist =row.type==2? res.data.payBack: res.data.houseBack
                row.back_id = ''
                row.back_num = ''
                row.back_amount = ''
                row.disburse_amount = ''
                row.back_summary = ''
                row.business_type = ''
                row.business_detail =''
            })
        },
        checkReceive(){
            this.receiveDialog = true
        },
        checkUser(msg){
            this.receivename = msg.name
            this.addform.b_a_id  = msg.id
            this.receiveDialog = false
        },
        closereceive(msg){
            this.receiveDialog = false
        },
        changeChinese(){
            if(this.addform.amount == ''){
                this.addform.amount_yuan = ''
                return
            }
            let number = Number(this.addform.amount.replace(/,/g, ""))
            if(number && number>=0){
                this.addform.amount_yuan = this.NumberToChinese(number)
            }else if(number && number<0){
                this.addform.amount_yuan ='负' + this.NumberToChinese(Number(number)*-1)
            }
        },
        getBusList(){
            let data  = {
                id:''
            }
            getbusselect(data).then(res =>{
                this.busList = res.data
            })
        },
        getBusDetail(){
            let data  = {
                id:this.addform.business_type
            }
            getbusselect(data).then(res =>{
                this.busdetailList = res.data
            })
        },
        getSelectList(){
            addselect().then(res =>{
                this.companyList = res.data.option.pay_company
                this.paytypeList = res.data.option.pay_type
                this.proofList = res.data.option.proof
                this.belongList = res.data.option.belong_company
                this.feeList = res.data.option.fee_type
                this.billList = res.data.option.bill_status
                this.statusList = res.data.option.audit_status
                this.accountList = res.data.option.pay_account
                this.branchList = res.data.option.branch
                this.payAccountList = res.data.option.pay_account
                if(this.branchList.length==1&&this.type==1){
                    this.$set(this.addform,'branch_id',this.branchList[0].id)
                }
                localStorage.setItem('x_token',res.data.token)
            })
        },
        getDetailList(row,index){
            for(var a = 0;a<row.backlist.length;a++){
                if(row.type == 1){
                    if(row.backlist[a].id == row.back_id){
                        row.back_num = row.backlist[a].num
                    }
                }else{
                    if(row.backlist[a].id == row.back_id){
                        row.back_num = row.backlist[a].number
                    }
                }
                
            }
            let data = {
                back_id:row.back_id,
                type:row.type
            }
            backdetail1(data).then(res =>{
                row.back_amount = row.type==2? Number(res.data.payBack.amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 }):Number(res.data.houseBack.pay_amount.replace(/,/g, "")).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
                row.back_summary = row.type==2?res.data.payBack.summary:''
                row.business_type = row.type==2?res.data.payBack.business_type:res.data.houseBack.business_type
                if(row.type ==1){
                    let detail_name = []
                    let detail = res.data.houseBack.business_detail
                    detail.forEach(item=>{
                        detail_name.push(item.business_detail.name)
                    })
                    row.business_detail = detail_name.toString()
                }else{
                    row.business_detail = row.type==2?res.data.payBack.business_detail:res.data.houseBack.business_detail
                }
                row.real_time = row.type==2?res.data.payBack.real_time:res.data.houseBack.real_time
                row.get_account = row.type==2?res.data.payBack.get_account:res.data.houseBack.pay_account
                row.channel = row.type==2 && res.data.payBack.contract.sell_info.channel.title ? res.data.payBack.contract.sell_info.channel.title : '无'
                row.channel_id = row.type==2 && res.data.payBack.contract.sell_info.channel.id ? res.data.payBack.contract.sell_info.channel.id : ''
                row.source = row.type==2 && res.data.payBack.contract.sell_info.source ? res.data.payBack.contract.sell_info.source : '无'
            })
        },
        delRow(row,index){
            this.addform.fee_apply_details.splice(index,1)
        },
        addDetail(){
            this.addform.fee_apply_details.push({
                back_amount:'',
                disburse_amount:'',
                back_id:'',
                business_type:'',
                business_detail:'',
                channel:'',
                channel_id:'',
                return_amount:'',
                source:'',
                type:'',
                backlist:[],
                back_summary:'',
            })
        },
        changTab(index){
            this.actTabIndex = index
        },
        delFile(index){
            this.fileList.splice(this.actIndex+index,1)
            this.actIndex = this.actIndex==0?this.actIndex:this.actIndex-1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        seeImageList(index){
            this.imageData.popup = true
            this.imageData.imgList = this.fileList
            this.imageData.index = this.actIndex + index
        },
        addOk(addform,type){
            if(this.addform.have == 1){
                let total_back_amount = 0
                this.addform.fee_apply_details.forEach(item =>{
                    total_back_amount  = total_back_amount + Number(item.disburse_amount.replace(/,/g, ""))
                })
                if(Number(this.addform.amount.replace(/,/g, "")) != total_back_amount.toFixed(2)){
                    this.$message.error('回款支出金额需等于费用申请金额')
                    return
                }
            }
            let is_channel = false
            this.addform.fee_apply_details.forEach(item =>{
                if(item.source != '渠道'){
                    is_channel = true
                }
            })
            if(this.addform.is_channel == 1 && this.addform.have == 2){
                this.$message.error('渠道返点需要提供回款明细！')
                return
            }
            if(this.addform.is_channel == 1 && is_channel){
                this.$message.error('渠道返点提供的回款明细需全为渠道来源！')
                return
            }
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.bill_status == 60 && this.fileList.length == 0){
                        this.$message.error('请上传附件')
                        return
                    }
                    let list = []
                    this.fileList.forEach(item =>{
                        list.push({
                            name:item.name,
                            image:item.img_url
                        })
                    })
                    this.addform.fee_apply_image = list
                    let data = JSON.parse(JSON.stringify(this.addform))
                    if(type==2){
                        data.audit_status=62
                    }else{
                        data.audit_status=63
                    }
                    data.bill_submit = this.addform.bill_status == 59 || this.addform.bill_status == 61 ? 0 : 1
                    this.$emit('add',data)
                } else {
                    return false;
                }
            });
            
        },
        editOk(addform,type){
            let is_channel = false
            this.addform.fee_apply_details.forEach(item =>{
                if(item.source != '渠道'){
                    is_channel = true
                }
            })
            if(this.addform.is_channel == 1 && this.addform.have == 2){
                this.$message.error('渠道返点需要提供回款明细！')
                return
            }
            if(this.addform.is_channel == 1 && is_channel){
                this.$message.error('渠道返点提供的回款明细需全为渠道来源！')
                return
            }
            this.$refs[addform].validate((valid) => {
                if (valid) {
                    if(this.addform.bill_status == 60 && this.fileList.length == 0){
                        this.$message.error('请上传附件')
                        return
                    }
                    if(this.addform.audit_status!=66){
                        if(type==2){
                            this.addform.audit_status=62
                        }else{
                            this.addform.audit_status=63
                        }
                    }
                    let list = []
                    this.fileList.forEach(item =>{
                        list.push({
                            name:item.name,
                            image:item.img_url
                        })
                    })
                    this.addform.fee_apply_image = list
                    let data = JSON.parse(JSON.stringify(this.addform))
                    // console.log(data)
                    // return
                    this.$emit('edit',data)
                } else {
                    return false;
                }
            });
        },
        cancel(){
            this.$emit('cancel',false)
        },
        clean(){
            
        },
        nextFile(){
            this.actIndex = this.actIndex + 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        lastFile(){
            this.actIndex = this.actIndex - 1
            this.showFile = this.fileList.slice(this.actIndex,this.actIndex + 4)
        },
        uploadFile(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            if(name.length > 40){
                this.$message.error('文件名称过长,请修改后重新上传')
                return 
            }
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
        },
        uploadFile2(obj){
            console.log(obj)
            let fileObj = obj.file
            const { size, name } = fileObj
            let md5code = md5(name + new Date().getTime())
            let length = 500000
            let start = 0
            let end = 0
            let i = 1;
            Loading.service({
                text: "上传中...."
            });
            this.testHandle(md5code, length, start, end, size, name, fileObj, i,'file')
        },
        testHandle(md5code, length, start, end, size, name, fileObj, i,where) {
            if (length > size) {
                length = size
            }
            if (end < size) {
                end = start + length
                if (end > size) {
                    end = size
                }
                let blob = ""
                blob = fileObj.slice(start, end)
                start = end
                let fd = new FormData()
                fd.append("file", blob)
                fd.append("md5code", md5code)
                let suc = 2
                if (end == size) {
                    suc = 1
                }
                fd.append("suc", suc)
                fd.append("tmp", i)
                fd.append("name", name)
                i = i + parseInt(1)
                service({
                    url: "/common/uploadImg",
                    method: "post",
                    data: fd,
                }).then(res => {
                    if (res.code == 200) {
                        if (suc == 2) {
                            this.testHandle(md5code, length, start, end, size, name, fileObj, i)
                        } else {
                            this.$message.success(res.msg)
                            Loading.service().close()
                            if (res.data.url !== undefined) {
                                if(!where){
                                    this.fileList.push({ name: name, img_url: res.data.url })
                                    if(this.showFile.length<4){
                                        this.showFile.push({ name: name, img_url: res.data.url })
                                    }
                                }else{
                                    this.fileList2.push({ name: name, file_url: res.data.url })
                                }
                            }
                        }
                    } else {
                        this.$message.error(res.msg)
                        Loading.service().close()
                    }
                })
                .catch(err => {
                    this.$message.error("请重新上传")
                    Loading.service().close()
                })
            }
        },
        NumberToChinese(money){
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');	 //汉字的数字
            var cnIntRadice = new Array('', '拾', '佰', '仟'); //基本单位
            var cnIntUnits = new Array('', '万', '亿', '兆');  //对应整数部分扩展单位
            var cnDecUnits = new Array('角', '分', '毫', '厘'); //对应小数部分单位
            var cnInteger = '整';	//整数金额时后面跟的字符
            var cnIntLast = '元';	//整数完以后的单位
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            var integerNum;	 //金额整数部分
            var decimalNum;	 //金额小数部分
            //输出的中文金额字符串
            var chineseStr = '';
            var parts;		//分离金额后用的数组，预定义
            if (money == '') { return ''; }
        
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '超出最大处理数字';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //四舍五入保留两位小数,转换为字符串
            money = Math.round(money * 100).toString();
            integerNum = money.substr(0,money.length-2);
            decimalNum = money.substr(money.length-2);
            if(decimalNum.length == 1){
                decimalNum = '0' + decimalNum
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '' || /^0*$/.test(decimalNum)) {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
    }
}
</script>

<style lang="scss" scoped>
.adduser {
    .headtab{
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding: 15px 0 10px 0 ;
        .tab-box{
            display: flex;
            justify-content: space-between;
            width: 132px;
            font-size: 14px;
            border-bottom: 1px solid #F0F1F5;
            box-sizing: content-box;
            height: 25px;
            .tab{
                color: #B0B1B9;
                height: 100%;
            }
            .tab:hover{
                color: #2C57FF;
            }
            .acttab{
                font-weight: bold !important;
                color: #6B6D77 !important;
                border-bottom:2px solid #2C57FF !important;
                width: auto;
                height: 100%;
            }
        }
    }
    .right.file-right{
        .filelist2{
            width: 100%;
            height: auto;
            max-height: 130px;
            overflow-y: auto;
            padding-right: 8px ;
            box-sizing: border-box;
            min-height: 0 !important;
            .fileline{
                display: flex;
                height: 28px;
                border-radius: 4px;
                background: #F4F7FC;
                width: 100%;
                align-items: center;
                margin-bottom: 6px;
                padding: 0 12px 0 8px;
                justify-content: space-between;
                box-sizing: border-box;
                .icon{
                    margin-right: 7px;
                    width: 12px;
                    height: 14px;
                }
                .name{
                    font-size: 12px;
                    color: #474952;
                }
                .close{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(63,69,124,0.1);
                    display: none;
                    align-items: center;
                    justify-content: center;
                    .closeicon{
                        width: 8px;
                        height: 8px;
                        fill: #B0B1B9;
                    }
                }
                .close:hover{
                    background: rgba(44, 87, 255, 0.2) !important;
                    .closeicon{
                        fill: #2C57FF !important;
                    }
                }
            }
            .fileline:hover{
                background:#EDF3FE !important;
                .close{
                    display: flex;
                }
            }
        }
    }
    .right{
        margin-left: 87px;
        .filelist1{
            width: 100%;
            overflow-y: auto;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: auto !important;
            min-height: 118px !important;
            .fileline:hover{
                background: #fff !important;
            }
            .fileline{
                height: auto !important;
                background: #fff !important;
                margin-top: 16px;
                width: auto !important;
                .file-box{
                    width: 70px;
                    height: 70px;
                    position: relative;
                    margin-right: 8px;
                    .image{
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                    .close{
                        position: absolute;
                        right: -7px;
                        top: -7px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #E6E6E6;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .closeicon{
                            width: 8px;
                            height: 8px;
                            fill: #B0B1B9;
                        }
                    }
                    .close:hover{
                        background: rgba(44, 87, 255, 0.2) !important;
                        .closeicon{
                            fill: #2C57FF !important;
                        }
                    }
                }
            }
            .left-btn{
                width: 20px;
                height: 32px;
                border-radius: 4px 0px 0px 4px;
                background: rgba(0,0,0,0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-right: 4px;
                .lefticon{
                    width: 7px;
                    height: 12px;
                }
            }
            .right-btn{
                width: 20px;
                height: 32px;
                border-radius: 0px 4px 4px 0px;
                background: rgba(0,0,0,0.2);
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-left: -4px;
                .righticon{
                    width: 7px;
                    height: 12px;
                    transform: rotate(180deg);
                }
            }
            .change:hover{
                background: rgba(0,0,0,0.3) !important;
            }
            .leftpart{
                width: 20px;
                margin-right: 4px;
            }
            .rightpart{
                width: 20px;
                margin-left:-4px;
            }
        }
    }
    .file{
        width: 100%;
        height: auto;
            .filepart{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            .filetip{
                font-size: 10px;
                color: #D0D0D2;
                margin-left: 8px;
                box-sizing: border-box;
                    padding-top: 8px;
                }
            .icon{
                margin-right: 7px;
            }
            .filesize{
                width: 100%;
                height: 12px !important;
                line-height: 12px;
                font-size: 12px;
                text-align: left;
                color: #D0D0D2;
            }
        }
    }
    .formbox{
        height: 430px;
        .el-select .el-input .el-select__caret{
            display: flex; 
            justify-content: center;
            align-items: center;
        }
        
        .table-main{
            padding: 14px 0 ;
            box-sizing: border-box;
            .table-title{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .title{
                    font-weight: bold;
                    color: #6B6D77;
                }
                .addpart{
                    .icon{
                        fill: #475B93 !important;
                    }
                }
                .addpart:hover{
                    color: #2C57FF !important;
                    .svg-icon{
                        fill: #2C57FF !important;
                    }
                }
                .addpart:active{
                    color: #133CDC !important;
                    .svg-icon{
                        fill: #133CDC !important;
                    }
                }
            }
            .table{
                margin-top: 12px;
            }
        }
    }
}
.handlerTab_tooltip{
    border: none !important;
        box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
        color: #6B6D77 !important;
        padding: 8px 16px !important;
        line-height: 20px !important;
}
.handlerTab_tooltip.is-light{
    border: none !important;
}
.handlerTab_tooltip .popper__arrow {
    /* 上方箭头 */
    border-top-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
    /* 下方箭头 */
    border-bottom-color: #fff !important;
    box-shadow: 0px 0px 16px 0 rgba(0,0,0,0.1) !important;
}
.remark{
    .el-textarea__inner{
        height: 80px !important;
        line-height: 26px !important;
        padding: 5px 12px !important;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    .el-textarea__inner:hover{
        border-color: #2C57FF !important;
        
    }
}
.checkcontract{
    width: auto !important;
}
</style>
