<template>
    <div class="table-part">
        <div class="table">
            <el-table :data="list" height="100%" style="width: 100%"
                :header-cell-style="{ 'background': '#F4F7FC', 'color': '#B0B1B9', 'fontWeight': 'bold' }"
                tooltip-effect="light" @selection-change="handleSelectionChange" @select="handleSelectionChange1"
                @select-all="handleSelectionChange1" :row-key="getRowKeys" v-loading="loading" ref="showTable" :span-method="objectSpanMethod" :class="{'merge-table':mergeIds.length > 0 }"
                :border="mergeIds.length > 0">
                <template slot="empty">
                    <el-empty :image-size="160" :image="image" description='暂无数据'></el-empty>
                </template>
                <el-table-column :key=-1 :reserve-selection="true" type="selection" width="55"
                    align="center"></el-table-column>
                <!-- <el-table-column :key=-2 type="index" width="100" label="序号" align="center"></el-table-column> -->
                <el-table-column :key=-3 label="操作" align="center" min-width="200" fixed="right">
                    <template slot-scope="scope">
                        <!-- <div v-if="scope.row.handle"> -->
                        <div class="chick" v-if="path == '/custom'">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'客户管理-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'客户管理-del'">删除</div>
                            <!-- <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'客户管理-del'">暂无操作</div> -->
                        </div>
                        <div class="chick" v-if="path == '/sellchance'">
                            <div class="hover handel" @click="perfectHouse(scope.row)" v-if="scope.row.handle">完善房号</div>
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'销售机会-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'销售机会-del'">删除</div>
                        </div>
                        <div class="chick" v-if="path == '/contract' ">
                            <div class="hover handel" v-if="scope.row.handle" @click="editUser(scope.row)" v-permission="'合同管理-edit'">编辑</div>
                            <div class="hover handel" v-if="scope.row.handle" @click="deleteUser(scope.row)" v-permission="'合同管理-del'">删除</div>
                            <div class="hover handel" v-if="scope.row.audit_status_id == 18 && !scope.row.is_perfect && scope.row.creator" @click="setOutworker(scope.row)">生成外勤</div>
                            <div class="hover handel" v-if="scope.row.audit_status_id == 18 && scope.row.is_perfect && scope.row.creator" @click="setOutworker(scope.row)" >完善资料</div>
                        </div>
                        <div class="chick" v-if="path == '/contractback' && scope.row.handle">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'回款记录-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'回款记录-del'">删除</div>
                        </div>
                        <div class="chick" v-if="path == '/constapply'">
                            <div class="hover handel"
                                v-if="scope.row.bill_status_id == 61 && scope.row.bill_submit != 1 && scope.row.not_sub"
                                @click="submitIt(scope.row)">提交票据</div>
                            <div class="hover handel"
                                v-if="scope.row.bill_status_id == 61 && scope.row.bill_submit == 1 && scope.row.not_sub"
                                @click="confirmIt(scope.row)">确认</div>
                            <div class="hover handel" v-if="scope.row.handle && scope.row.audit_status == 62 || scope.row.handle && scope.row.audit_status != 62 && is_super" @click="editUser(scope.row)" v-permission="'费用申请-edit'">编辑</div>
                            <div class="hover handel" v-if="scope.row.audit_status != 62  && scope.row.handle && scope.row.audit_status != 66 && !is_super" @click="editUser(scope.row)" v-permission="'费用申请-edit'">重新提交</div>
                            <div class="hover handel" v-if="scope.row.handle" @click="deleteUser(scope.row)" v-permission="'费用申请-del'">删除</div>
                        </div>
                        <div class="chick" v-if="path == '/housecontract' && scope.row.handle">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'房屋合同-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'房屋合同-del'">删除</div>
                        </div>
                        <div class="chick" v-if="path == '/housecollection' && scope.row.handle">
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'房屋回款-edit'">编辑</div>
                            <div class="hover handel" @click="deleteUser(scope.row)" v-permission="'房屋回款-del'">删除</div>
                        </div>
                        <div class="chick" v-if="path == '/openbill'">
                            <div class="hover handel" v-if="scope.row.handle" @click="editUser(scope.row)"
                                v-permission="'开票管理-edit'">编辑</div>
                            <div class="hover handel" v-if="scope.row.handle" @click="deleteUser(scope.row)"
                                v-permission="'开票管理-del'">删除</div>
                            <div class="hover handel" @click="improveBack(scope.row)"
                                v-if="scope.row.payback != '是' && scope.row.perfect">完善回款单号</div>
                        </div>
                        <div class="chick" v-if="path == '/channel'">
                            <div class="hover handel" @click="seeHistory(scope.row)"  v-permission="'渠道管理-teamHistory'">合作记录</div>
                            <div class="hover handel" @click="editUser(scope.row)" v-permission="'渠道管理-editChannel'">编辑</div>
                        </div>
                        <!-- </div>
                        <div v-else>
                            <span>暂无操作</span>
                        </div> -->
                    </template>
                </el-table-column>

                <div v-for="(item, index) in head" :key="index">
                    <!-- <el-table-column :key="index" v-if="item.prop == 'number'" :label="item.name" width="150">
                        <template slot-scope="scope">
                            <div class="hover handel " @click="seeContarct(scope.row)">{{ scope.row.number }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'is_del'" :label="item.name" width="50">
                        <template slot-scope="scope">
                            <div>{{ scope.row.is_del == 0 ? '删除' : '正常' }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'name'" :label="item.name" min-width="300" width="auto">
                        <template slot-scope="scope">
                            <span style="color:#475b93" class="hover handel" @click="seeDeatail(scope.row)"> {{
                                scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'num'" :label="item.name" width="150">
                        <template slot-scope="scope">
                            <span style="color:#475b93" class="hover handel" @click="oddnum(scope.row)"> {{
                                scope.row.num }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'client_id'" :label="item.name" width="300">
                        <template slot-scope="scope">
                            <span style="color:#475b93" class="hover handel" @click="seeCum(scope.row, 1)"> {{
                                scope.row.client_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'client'" :label="item.name" width="300">
                        <template slot-scope="scope">
                            <span style="color:#475b93" class="hover handel" @click="seeCum(scope.row, 2)"> {{
                                scope.row.client }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'house_contract'" :label="item.name" width="300">
                        <template slot-scope="scope">
                            <span style="color:#475b93" class="hover handel" @click="seeHe(scope.row)"> {{
                                scope.row.house_contract }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column :key="index" v-if="item.name == '客户名称'&&path == '/custom'" :label="item.name" width="250">
                        <template slot-scope="scope">
                            <div  class="old-name">
                                <!-- <div class="hover handel out-text " @click="seeCustom(scope.row)">{{scope.row.name }}</div> -->
                                <el-tooltip placement="top" popper-class="popper-element-class" :content="scope.row.name" effect="light">
                                    <div class="hover handel out-text " :class="{'common-name':!scope.row.old}" @click="seeCustom(scope.row)">{{scope.row.name }}</div>
                                </el-tooltip>
                                <div class="old-box" v-if="scope.row.old">曾用名</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '系统来源'&&path == '/custom'" :label="item.name" width="100" :prop="item.prop" show-overflow-tooltip></el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '客户名称'&&path != '/custom'" :label="item.name" width="250" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeCustom(scope.row)">
                                {{
                                    path == '/custom' ? scope.row.name : path == '/contractback' ? scope.row.cl_id : path == '/housecontract' || path == '/housecollection' || path == '/openbill' ? scope.row.client : scope.row.client_id
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '机会名称' || item.name == '销售机会'" :label="item.name"
                        min-width="180">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeSell(scope.row)">
                                {{ path == '/sellchance' ? scope.row.name : path == '/contractback' ? scope.row.s_id : scope.row.sell_id
                                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '合同单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeCont(scope.row)"> {{
                                path == '/contract' ? scope.row.number : scope.row.ca_id }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column :key="index" v-else-if="path=='/contract'&&item.name == '所属部门'" :label="item.name" min-width="250">
                        <template slot-scope="scope">
                            <span > {{scope.row.branch_id}}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column :key="index" v-else-if="item.name == '回款单号' && path !='/openbill'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeContBack(scope.row)">
                                {{ path == '/contractback' ? scope.row.number : scope.row.back_number }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '回款单号' && path =='/openbill'"  :label="item.name" min-width="150" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                <span class="hover handel" v-for="(name,name_id) in scope.row.back_arr" :key="name_id" @click="seeContBack1(scope.row,name_id)">
                                    {{ name }}{{ name_id == scope.row.back_arr.length - 1 ? '': '、' }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '费用申请单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeApply(scope.row)"> {{
                                scope.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '房屋合同单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeHouse(scope.row)">
                                {{ path == '/housecontract' ? scope.row.num : scope.row.house_num }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '租赁合同'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeHouse(scope.row)">
                                {{ path == '/housecontract' ? scope.row.num : scope.row.house_contract }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '房屋回款单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeHouseBack(scope.row)"> {{
                                path == '/housecollection' ? scope.row.num : scope.row.house_contract }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '发票单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeBill(scope.row)"> {{
                                scope.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '开票单号'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeBill1(scope.row)"> {{
                                scope.row.bill_num }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '合同单项明细'" :label="item.name" min-width="150">
                        <template slot-scope="scope">
                            <span class="hover handel" @click="seeBusDetail(scope.row)"> {{
                                scope.row.c_s_id }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column sortable v-else-if="item.prop == 'plan_amount'" :label="item.name" width="300">
                        <template slot-scope="scope">
                            <span style="color:#475b93"> {{
                                Number(scope.row.plan_amount)
                            }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column :key="index" v-else-if="item.name == '开启关闭' && path !== '/channel'" :label="item.name" min-width="200">
                        <template slot-scope="scope">
                            <div>
                                <el-switch
                                    v-model="scope.row.status"
                                    active-color="#45B95F" 
                                    inactive-color="#DADCE3"
                                    @change="openProcess(scope.row)">
                                </el-switch>
                            </div>
                        </template>
                        <!-- && localStorage.getItem('userbtn').indexof('渠道管理-channelStatus') > 0 -->
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '开启关闭' && path == '/channel' " :label="item.name" min-width="200" >
                        <template slot-scope="scope">
                            <div  v-permission="'渠道管理-channelStatus'">
                                <el-switch
                                    v-model="scope.row.status"
                                    active-color="#45B95F" 
                                    inactive-color="#DADCE3"
                                    @change="openProcess(scope.row)">
                                </el-switch>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="path !== '/openbill' && item.prop == 'audit_status'"
                        :label="item.name" width="150">
                        <template slot-scope="scope">
                            <div class="type-status">
                                <span
                                    :class="scope.row.audit_status == '未审核' ? 'status1' : scope.row.audit_status == '未提审' ? 'status1' : scope.row.audit_status == '审核中' ?
                                        'status2' : scope.row.audit_status == '审核通过' ? 'status3' : scope.row.audit_status == '财务已确认' ? 'status5' : 'status4'">
                                    {{ scope.row.audit_status }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="path == '/openbill' && item.prop == 'audit_status'"
                        :label="item.name" width="150">
                        <template slot-scope="scope">
                            <div class="type-status">
                                <span
                                    :class="scope.row.audit_status == '未提审' ? 'status1' : scope.row.audit_status == '审核中' ?
                                        'status2' : scope.row.audit_status == '已开票' ? 'status3' : scope.row.audit_status == '财务已确认' ? 'status5' : 'status4'">
                                    {{ scope.row.audit_status }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'audit_status_name'" :label="item.name" width="150">
                        <template slot-scope="scope">
                            <div class="type-status">
                                <span
                                    :class="scope.row.audit_status_name == '未提审' ? 'status1' : scope.row.audit_status_name == '审核中' ?
                                        'status2' : scope.row.audit_status_name == '审核通过' ? 'status3' : scope.row.audit_status_name == '财务已确认' ? 'status5' : 'status4'">
                                    {{ scope.row.audit_status_name }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="path == '/contractback' && item.prop == 'type'" :label="item.name"
                        width="150">
                        <template slot-scope="scope">
                            <div class="type-status">
                                <span
                                    :class="scope.row.type == '未提审' ? 'status1' : scope.row.type == '审核中' ?
                                        'status2' : scope.row.type == '审核通过' ? 'status3' : scope.row.type == '财务已确认' ? 'status5' : 'status4'">
                                    {{ scope.row.type }}
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.prop == 'stage_id'" :label="item.name" width="150">
                        <template slot-scope="scope">
                            <!-- <span style=""> {{scope.row.stage_id }}</span> -->
                            <div class="sell"
                                :class="scope.row.stage_id == '初步接洽' ? 'sell-status1' : scope.row.stage_id == '需求确定' ?
                                    'sell-status2' : scope.row.stage_id == '谈判审核' ? 'sell-status3' : scope.row.stage_id == '赢单' ? 'sell-status4' : ''">
                                {{ scope.row.stage_id }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '所属部门'" :prop="item.prop" :label="item.name" :width="150" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '法定代表人'" :prop="item.prop" :label="item.name" :width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '负责人'" :prop="item.prop" :label="item.name" :width="120" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column :key="index" v-else-if="item.name == '创建人'" :prop="item.prop" :label="item.name" :width="170" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column :key="index" v-else :prop="item.prop" :label="item.name" :min-width="180"
                        show-overflow-tooltip>
                    </el-table-column>
                </div>
            </el-table>
        </div>
    </div>
</template>

<script>
import adduser from '@/utils/mutual.js'
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        head: {
            type: Array,
            default: () => {
                return []
            }
        },
        loading: {
            type: Boolean,
            default: () => {
                return true
            }
        },
        mergeIds:{
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            image: require('../../assets/image/none.png'),
            cellSty: {
                'background-color': '#aaa'
            },
            multipleSelection: [],
            path: '',
            is_super:false,
            // BatchStr: ''
        };
    },
    mounted() {
        this.path = this.$route.path
        let array = localStorage.getItem('userrole').split(',')
        array.forEach(item =>{
            if(item == 1){
                this.is_super = true
            }
        })
        this.editUser = this.$debounce(this.editUser)
        this.deleteUser = this.$debounce(this.deleteUser)
        this.confirmIt = this.$debounce(this.confirmIt)
        this.improveBack = this.$debounce(this.improveBack)
        this.perfectHouse = this.$debounce(this.perfectHouse)
        this.seeCustom = this.$debounce(this.seeCustom)
        this.seeSell = this.$debounce(this.seeSell)
        this.seeCont = this.$debounce(this.seeCont)
        this.seeContBack = this.$debounce(this.seeContBack)
        this.seeApply = this.$debounce(this.seeApply)
        this.seeHouse = this.$debounce(this.seeHouse)
        this.seeHouseBack = this.$debounce(this.seeHouseBack)
        this.seeBill = this.$debounce(this.seeBill)
        this.seeBusDetail = this.$debounce(this.seeBusDetail)
    },
    methods: {
        perfectHouse(row){
            this.$emit('perfect', row.id)
        },
        seeBusDetail(row) {
            this.$emit('seebus', row.c_single_id)
        },
        seeCustom(row) {
            if (this.path == '/custom') {
                this.$emit('detail', row)
            } else {
                this.$router.push({
                    path: '/custom',
                    query: {
                        id:this.path == '/housecontract' || this.path == '/housecollection' ? row.client_id : row.new_client_id,
                        type: 'detail'
                    }
                })
            }
        },
        seeSell(row) {
            if (this.path == '/sellchance') {
                this.$emit('detail', row)
            } else {
                this.$router.push({
                    path: '/sellchance',
                    query: {
                        id: row.new_sell_id,
                        type: 'detail'
                    }
                })
            }
        },
        seeCont(row) {
            if (this.path == '/contract') {
                this.$emit('detail', row)
            } else {
                this.$router.push({
                    path: '/contract',
                    query: {
                        id: row.new_ca_id,
                        type: 'detail'
                    }
                })
            }
        },
        openProcess(row){
            this.$emit('open',row)
        },
        seeContBack(row) {
            if (this.path == '/contractback') {
                this.$emit('detail', row)
            } else {
                this.$router.push({
                    path: '/contractback',
                    query: {
                        id: row.back_number_id,
                        type: 'detail'
                    }
                })
            }
        },
        seeContBack1(row,index) {
            if(row.way == '房屋开票'){
                
                this.$router.push({
                    path: '/housecollection',
                    query: {
                        id: row.back_ids[index],
                        type: 'detail'
                    }
                })  
            }else{
                this.$router.push({
                    path: '/contractback',
                    query: {
                        id: row.back_ids[index],
                        type: 'detail'
                    }
                })            
            }
        },
        seeApply(row) {
            this.$emit('detail', row)
        },
        seeHouse(row) {
            if (this.path == '/housecontract') {
                this.$emit('detail', row)
            } else {
                this.$router.push({
                    path: '/housecontract',
                    query: {
                        id: row.house_num_id ? row.house_num_id : row.house_contract_id,
                        type: 'detail'
                    }
                })
            }
        },
        seeHouseBack(row) {
            this.$emit('detail', row)
        },
        seeBill(row) {
            this.$emit('detail', row)
        },
        seeBill1(row) {
            this.$router.push({
                name: 'openbill',
                params: {
                    id: row.bill_id ? row.bill_id : row.bill_id,
                    type: 'detail'
                }
            })
        },
        handleSelectionChange1(row) {
            this.$emit('select', row)
        },
        getRowKeys(row) {
            if(this.path == '/housecollection'){
                return row.cow_id
            }else{
                return row.id;
            }
        },
        seeDeatail(row) {
            // console.log(row);
            this.$emit('detail', row)
        },
        seeHistory(row){
            this.$emit('history', row)
        },
        seeCum(row, num) {
            if (num === 1) {
                this.$bus.$emit('data', [{
                    icon:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAkxJREFUOE+llD9oFEEYxX/fesLdKSixSSFoEbAzimIuEIl2NknUFBYRTJeARVIETHHgCVdckSJlUolgCgXBOxQshJyo5CKiSWdh+hQikRgvRdgns9ldj3XzRzOwsMzM99tv3ns7xi5DUgYYAorAFlAB5szMvacO22lB0mAI6EjsWQJKZlZNq/0LKKkXmAIu7tY9kAqOgSGoBFzZA5RcrgPjZrbsFkxSZ3i0a/8ISm5/HkghqQlkDwiLyr854BhQBo4eELrm0hBoKKkduAeM/ke3DjTtHjP7YaV5Zc5mKNy8bO9C8H1geB/gTWDG6fagzkZnlp4b3Va3WkNvgR7BkkGpv2BVSWdCo66nyOBC/djJZGYrtYYGERWMDkHDAReAQlSYALsEuChFYAdyoV6pfVCvfKasJa8SrwINq4u6gygZnI7B4rXnMdHXZcthx1sRCD/4SJxXia9mTPYX7Fkc7IfzyrblGDGYBJxJ0XD5cp25cbulWxwIj/LnX8yVrm7/31Zb1Ag+4zJmvjeZdZNtOe6GETi+Q5RWfSgvNZk9BZkTWVz0hvGYsuqC1s3iDK4KKg58LM+RjCgiRrHt4AvWMKbzh5h+v87G+TxD5gwJTyTx0140dMHfvgz+/MPiizwqA1326Om82rM5xhBrucPMxCCfonO25QR1X0zGGtYaGpBzEM4lHf/U5KWbCzpKgAQfzWOi/5K9CTRMapQGRmwKtlqkCQzxoNjXbU9aGakXbOB4nluIssHJ1oI0Z/cERhuiKAEToSkV52wUkbQE/Aa/+/jywvZgUQAAAABJRU5ErkJggg==",
                    id:2, 
                    name:"icon1", 
                    path:"/custom", 
                    title:"客户管理"
                }, row, 1])
            }
            if (num === 2) {
                this.$bus.$emit('data', [{
                    icon:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAkxJREFUOE+llD9oFEEYxX/fesLdKSixSSFoEbAzimIuEIl2NknUFBYRTJeARVIETHHgCVdckSJlUolgCgXBOxQshJyo5CKiSWdh+hQikRgvRdgns9ldj3XzRzOwsMzM99tv3ns7xi5DUgYYAorAFlAB5szMvacO22lB0mAI6EjsWQJKZlZNq/0LKKkXmAIu7tY9kAqOgSGoBFzZA5RcrgPjZrbsFkxSZ3i0a/8ISm5/HkghqQlkDwiLyr854BhQBo4eELrm0hBoKKkduAeM/ke3DjTtHjP7YaV5Zc5mKNy8bO9C8H1geB/gTWDG6fagzkZnlp4b3Va3WkNvgR7BkkGpv2BVSWdCo66nyOBC/djJZGYrtYYGERWMDkHDAReAQlSYALsEuChFYAdyoV6pfVCvfKasJa8SrwINq4u6gygZnI7B4rXnMdHXZcthx1sRCD/4SJxXia9mTPYX7Fkc7IfzyrblGDGYBJxJ0XD5cp25cbulWxwIj/LnX8yVrm7/31Zb1Ag+4zJmvjeZdZNtOe6GETi+Q5RWfSgvNZk9BZkTWVz0hvGYsuqC1s3iDK4KKg58LM+RjCgiRrHt4AvWMKbzh5h+v87G+TxD5gwJTyTx0140dMHfvgz+/MPiizwqA1326Om82rM5xhBrucPMxCCfonO25QR1X0zGGtYaGpBzEM4lHf/U5KWbCzpKgAQfzWOi/5K9CTRMapQGRmwKtlqkCQzxoNjXbU9aGakXbOB4nluIssHJ1oI0Z/cERhuiKAEToSkV52wUkbQE/Aa/+/jywvZgUQAAAABJRU5ErkJggg==", 
                    id:2, 
                    name:"icon1", 
                    path:"/custom", 
                    title:"客户管理"
                }, 
                row, 2])
            }
        },
        seeHe(row) {
            this.$bus.$emit('data', [{
                id : 15 , 
                name : "housecollection", 
                path:"/housecollection", 
                title:"房屋回款"
            }, row])
        },
        setType(row) {
            console.log(row.is_open)
            let data = {
                id: row.id,
                freeze: row.is_open === true ? 1 : 0
            }
            this.$emit('stopuser', data)
        },
        addUser() {
            this.$emit('transfer', true)
        },
        editUser(row) {
            let data = {
                info: row,
                type: true
            }
            this.$emit('tableedit', data)
        },
        improveBack(row) {
            let data = {
                id: row.id,
                client_name: row.client,
                client_id: row.new_client_id,
                back_num: []
            }
            this.$emit('improve', data)
        },
        deleteUser(row) {
            let tip = '此操作将永久删除该数据, 是否继续?'
            if (this.$route.path == '/openbill') {
                tip = '此操作将永久删除该数据, 是否继续?'
            }
            this.$confirm(tip, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('deluser', row.id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleSelectionChange(val) {
            // console.log(val);
            this.multipleSelection = val;
            let Arr = val.map((item) => {
                return item.id
            })
            this.$emit('PL', Arr)

            // console.log(Arr);
            let Str = Arr.join(',')
            // console.log(Str);
            this.BatchStr = Str
            this.$emit('BS', Str)
            // console.log(Str);

        },
        oddnum(row) {
            this.$emit('detail', row)
        },
        collnum(row) {
            this.$emit('detail', row)

        },
        confirmIt(row) {
            this.$emit('confirm', row)
        },
        submitIt(row) {
            this.$emit('submit', row)
        },
        setOutworker(row){
            this.$emit('outwork',row)
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }){
            if(this.path == '/housecollection'){
                if (!this.mergeIds.includes(columnIndex - 1)) {
                    if(row.count>0){
                        return {
                            rowspan: row.count,
                            colspan: 1
                        };
                    }
                    else{
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        },
    }
}
</script>
<style lang="scss">
.el-table tbody tr:hover>td {
    background-color: #EDF3FE !important
}
</style>

<style lang="scss" scoped>
.table-part {
    .table {
        margin-top: 8px;
        width: 100%;
        // height: 560px;
        height: calc(100vh - 365px);

        .chick {
            display: flex;
            justify-content: space-evenly;
        }
        .old-name{
            display: flex;
            align-items: center;
            .out-text{
                max-width: 170px;
            }
            .common-name{  
                max-width: none !important;
                width: 100% !important;
            }
            .old-box{
                width: 56px;
                height: 22px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #E2E5EA;
                line-height: 22px;
                font-size: 12px;
                color: #6B6D77;
                text-align: center;
                margin-left: 4px;
            }
        }
        

        // .is-scrolling-left{
        //     height:512px !important;
        // }
        // .el-table__fixed-body-wrapper{
        //     height: 512px !important;
        //     top:48px !important;
        // }
        .type-status {
            span {
                font-size: 14px;
                border-radius: 4px;
                width: auto;
                padding: 3px 10px;
                line-height: 24px;
            }

            .status1 {
                color: #B0B1B9;
                background: #E9EAEE;
            }

            .status2 {
                color: #2C57FF;
                background: #EAF1FB;
            }

            .status3 {
                color: #10C257;
                background: #EDFBEF;
            }

            .status4 {
                color: #F74B4B;
                background: #FFF3F3;
            }

            .status5 {
                color: #DE8500;
                background: #FFEECD;
            }
        }

        .sell {
            width: 76px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            text-align: center;
            line-height: 24px;
            color: #B0B1B9;
            background: #E9EAEE;
        }

        .sell-status1 {
            color: #DE8500;
            background: #FAF5E6;
        }

        .sell-status2 {
            color: #0C80EB;
            background: #EAF7FF;
        }

        .sell-status3 {
            color: #2C57FF;
            background: #EAF1FB;
        }

        .sell-status4 {
            color: #08B54D;
            background: #EDFBEF;
        }

    }
}</style>
